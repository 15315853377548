<template>
  <div :class="$style.contacts">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link + 'contacts'" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <h2 :class="$style.name">Страница контакты Addwine</h2>
      <div :class="$style.showroom">
        <h3>Шоурум</h3>
        <el-form-item label="Заголовок" prop="showroom.title">
          <el-input v-model="form.showroom.title" />
        </el-form-item>
        <el-form-item
          label="Кнопка обр. связи"
          prop="showroom.feedbackButtonCaption"
        >
          <el-input v-model="form.showroom.feedbackButtonCaption" />
        </el-form-item>

        <el-form-item label="SEO-тексты">
          <SeoTextsBlock :seoTexts="form.seoTexts" @getSeo="getSeoTexts" />
        </el-form-item>

        <el-form-item label="SEO-FAQ">
          <SeoFaqsBlock :seoFAQs="form.seoFaqs" @getSeo="getSeoFaqs" />
        </el-form-item>

        <h4>Координаты карты:</h4>
        <div :class="$style.coordinates">
          <el-form-item
            label-width="12.5rem"
            label="Долгота"
            prop="showroom.mapCoordinates.lng"
          >
            <el-input-number v-model="form.showroom.mapCoordinates.lng" />
          </el-form-item>
          <el-form-item
            label-width="12.5rem"
            label="Широта"
            prop="showroom.mapCoordinates.lat"
          >
            <el-input-number v-model="form.showroom.mapCoordinates.lat" />
          </el-form-item>
        </div>
        <h4>Телефоны:</h4>
        <div :class="$style.phones">
          <div
            v-for="(item, index) in form.showroom.phones"
            :key="index"
            :class="$style.phone"
          >
            <span :class="$style.number">{{ index + 1 }}.</span>
            <div :class="$style.phoneData">
              <el-select v-model="item.selectOption" :class="$style.select">
                <el-option
                  v-for="option in phoneOptions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                >
                </el-option>
              </el-select>
              <el-form-item
                label-width="5rem"
                label="Телефон"
                :prop="'showroom.phones.' + index + '.phone'"
                :rules="{
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.phone"></el-input>
              </el-form-item>
              <el-form-item
                label-width="5rem"
                label="Описание"
                :prop="'showroom.phones.' + index + '.caption'"
              >
                <el-input v-model="item.caption"></el-input>
              </el-form-item>
              <el-form-item
                label-width="5rem"
                label="Ссылка"
                :prop="'showroom.phones.' + index + '.link'"
                :rules="{
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.link"></el-input>
              </el-form-item>
            </div>
            <el-button
              type="danger"
              plain
              circle
              icon="el-icon-delete"
              :class="$style.remove"
              @click="removePhone(index)"
            />
          </div>
        </div>
        <el-button
          size="small"
          type="primary"
          @click="addPhone"
          :class="$style.addPhone"
          >Добавить телефон</el-button
        >
        <el-form-item label="Часы работы" prop="showroom.workingHours">
          <el-input type="textarea" v-model="form.showroom.workingHours" />
        </el-form-item>
        <el-form-item label="Почта" prop="showroom.email">
          <el-input v-model="form.showroom.email" />
        </el-form-item>
        <div :class="$style.address">
          <h4>Адрес:</h4>
          <el-form-item label="Страна" prop="showroom.address.state">
            <el-input v-model="form.showroom.address.state" />
          </el-form-item>
          <el-form-item label="Город" prop="showroom.address.city">
            <el-input v-model="form.showroom.address.city" />
          </el-form-item>
          <el-form-item label="Улица" prop="showroom.address.street">
            <el-input v-model="form.showroom.address.street" />
          </el-form-item>
          <el-form-item
            label="Почтовый индекс"
            prop="showroom.address.postCode"
          >
            <el-input v-model="form.showroom.address.postCode" />
          </el-form-item>
        </div>
      </div>
      <div :class="$style.legalInformation">
        <h3>Юридическая информация</h3>
        <el-form-item label="Страна" prop="legalInformation.address.state">
          <el-input v-model="form.legalInformation.address.state" />
        </el-form-item>
        <el-form-item label="Город" prop="legalInformation.address.city">
          <el-input v-model="form.legalInformation.address.city" />
        </el-form-item>
        <el-form-item label="Улица" prop="legalInformation.address.street">
          <el-input v-model="form.legalInformation.address.street" />
        </el-form-item>
        <el-form-item
          label="Почтовый индекс"
          prop="legalInformation.address.postCode"
        >
          <el-input v-model="form.legalInformation.address.postCode" />
        </el-form-item>
        <el-form-item label="ИНН" prop="legalInformation.inn">
          <el-input v-model="form.legalInformation.inn" />
        </el-form-item>
        <el-form-item label="ОГРН" prop="legalInformation.ogrn">
          <el-input v-model="form.legalInformation.ogrn" />
        </el-form-item>
        <el-form-item label="Р/С" prop="legalInformation.checkingAccount">
          <el-input v-model="form.legalInformation.checkingAccount" />
        </el-form-item>
        <el-form-item label="ИП" prop="legalInformation.ie">
          <el-input v-model="form.legalInformation.ie" />
        </el-form-item>
        <el-form-item label="В ПАО Сбербанк" prop="legalInformation.sber">
          <el-input v-model="form.legalInformation.sber" />
        </el-form-item>
        <el-form-item label="К/С" prop="legalInformation.correspondentAccount">
          <el-input v-model="form.legalInformation.correspondentAccount" />
        </el-form-item>
        <el-form-item
          label="Фото расположения"
          prop="legalInformation.mapImage"
          :class="$style.uploader"
        >
          <Uploader
            without-caption
            :limit="1"
            :files="
              form.legalInformation.mapImage
                ? [{ original: form.legalInformation.mapImage }]
                : []
            "
            @upload="uploadImages"
          />
        </el-form-item>
      </div>
      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import SeoBlock from '@/components/moleculs/SEO.vue'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'

export default {
  components: {
    SeoBlock,
    Uploader,
    SeoTextsBlock,
    SeoFaqsBlock,
  },
  async created() {
    await this.getContent()
  },
  data() {
    return {
      phoneOptions: [
        { value: 'tel:', label: 'Телефон' },
        { value: 'https://api.whatsapp.com/send/?phone=', label: 'Whatsapp' },
        {
          value: 'https://t.me/addwine_market?text=',
          label: 'Telegram',
        },
      ],
      form: {
        seoTexts: [],
        seoFaqs: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
        showroom: {
          title: '',
          phones: [
            {
              phone: '',
              caption: '',
              link: '',
            },
          ],
          workingHours: '',
          email: '',
          feedbackButtonCaption: '',
          address: {
            city: '',
            street: '',
            state: '',
            postCode: '',
          },
          mapCoordinates: {
            lat: '',
            lng: '',
          },
        },
        legalInformation: {
          inn: '',
          ogrn: '',
          checkingAccount: '',
          ie: '',
          sber: '',
          correspondentAccount: '',
          address: {
            city: '',
            street: '',
            state: '',
            postCode: '',
          },
          mapCoordinates: {
            lat: '',
            lng: '',
          },
        },
      },
      rules: {
        showroom: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          feedbackButtonCaption: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          mapCoordinates: {
            lat: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              {
                type: 'number',
                required: true,
                message: 'Пожалуйста, введите числовое значение',
                trigger: 'blur',
              },
            ],
            lng: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              {
                type: 'number',
                required: true,
                message: 'Пожалуйста, введите числовое значение',
                trigger: 'blur',
              },
            ],
          },
          workingHours: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          email: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          address: {
            state: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            city: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            street: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            postCode: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
          },
        },
        legalInformation: {
          address: {
            state: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            city: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            street: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            postCode: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
          },
          inn: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogrn: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          checkingAccount: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ie: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          sber: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          correspondentAccount: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          mapImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  methods: {
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    parsingPhoneOptions(phoneData) {
      let selectOption = this.phoneOptions[0].value
      let link = ''
      const currentPhoneOption = this.phoneOptions.find((phoneOption) =>
        phoneData.link.includes(phoneOption.value),
      )

      if (currentPhoneOption) {
        selectOption = currentPhoneOption.value
        link = phoneData.link.replace(selectOption, '')
      }

      return {
        selectOption,
        link,
        phone: phoneData.phone,
        caption: phoneData.caption,
      }
    },
    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(
          'contacts',
          'addwine',
        )
      loading.close()

      if (error) return
      this.form = value.content
      if (value.content.showroom?.phones?.length) {
        value.content.showroom.phones = value.content?.showroom?.phones?.map(
          (phone) => this.parsingPhoneOptions(phone),
        )
      }
      this.form.seoTexts =
        this.form?.seoTexts?.map((seoText) => seoText.id) ?? []
      this.form.seoFaqs = this.form?.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []
    },
    addPhone() {
      this.form.showroom.phones.push({
        phone: '',
        caption: '',
        link: '',
        selectOption: this.phoneOptions[0].value,
      })
    },
    removePhone(index) {
      this.form.showroom.phones.splice(index, 1)
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const phones = this.form.showroom?.phones?.map((phoneData) => {
            return {
              phone: phoneData.phone,
              caption: phoneData.caption,
              link: phoneData.selectOption + phoneData.link,
            }
          })

          const seoTexts = this.form.seoTexts?.map((seoText) => ({
            id: seoText,
          }))

          const seoFaqs = this.form.seoFaqs?.map((seoFaq) => ({
            id: seoFaq,
          }))

          this.form.showroom.phones = phones

          this.form.showroom.mapCoordinates = {
            lat: `${this.form.showroom.mapCoordinates.lat}`,
            lng: `${this.form.showroom.mapCoordinates.lng}`,
          }

          const data = {
            ...this.form,
            seoTexts,
            seoFaqs,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'contacts',
            'addwine',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }

          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })

          this.$router.push('/addwine/content/pages')
        }
      })
    },

    uploadImages(images) {
      this.form.legalInformation.mapImage = images.length
        ? images[0].original
        : ''
    },
  },
}
</script>

<style lang="scss" module>
.contacts {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .form {
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
    h3 {
      margin-bottom: 1rem;
    }
    .showroom {
      .seoTexts {
        width: 100%;
      }
      .coordinates {
        display: flex;
        margin-top: 1rem;
      }
      .phones {
        padding-top: 1rem;
        margin-left: 7.5rem;
        max-width: 55rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        .phone {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 25rem;
          .phoneData {
            & > div:last-child {
              margin-bottom: 0;
            }
          }
          .number {
            margin-right: 2rem;
          }
          .select {
            width: 100%;
            margin-bottom: 1rem;
          }
          .remove {
            margin-left: 1rem;
          }
        }
      }
      .addPhone {
        margin: 2rem 0 2rem 10rem;
      }
    }
  }
}
</style>
