import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=403d2ca5"
import script from "./Contacts.vue?vue&type=script&lang=js"
export * from "./Contacts.vue?vue&type=script&lang=js"
import style0 from "./Contacts.vue?vue&type=style&index=0&id=403d2ca5&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports